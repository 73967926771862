import React, { useEffect, useRef } from 'react';
import { dsnCN } from '../../hooks/helper';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { splittingItems } from '../../hooks/Spltting';

function SocialMedia({ className }) {
    const ref = useRef();
    const socialData = [
        // {link: "#0", icon: faFacebookF},
        // {link: "#0", icon: faTwitter},
        { link: 'https://www.instagram.com/kontigo_events/', icon: faInstagram },
        { link: 'https://www.youtube.com/@kontigo-events9055', icon: faYoutube },
    ];

    useEffect(() => {
        splittingItems(ref.current, 'li');
    });

    return (
        <ul className={dsnCN('social-one', className)} ref={ref}>
            {socialData.map((item, index) => (
                <li key={index}>
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={item.icon} />
                    </a>
                </li>
            ))}
        </ul>
    );
}

export default SocialMedia;
