const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function generateString(length) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

/**
 *
 * @param startTime
 * @param endTime
 * @param duration
 * @param current_timer
 * @returns {number}
 */
export function pageLoad(startTime, endTime, duration, current_timer) {
    const performanceTiming = window.performance.timing,
        time =
            ((((performanceTiming.loadEventEnd - performanceTiming.navigationStart) * -1) / 1000) %
                50) *
            10;

    let current = startTime,
        increment = endTime > startTime ? 1 : -1,
        stepTime = Math.abs(Math.floor((time + duration) / 100));

    const timer = setInterval(function () {
        current += increment;
        current_timer(current);
        if (current >= endTime) {
            clearInterval(timer);
        }
    }, stepTime);

    return timer;
}

export function dsnCN(...args) {
    return args.join(' ').trim();
}

export function progressBar() {
    // console.log('started...');
    // const progressBar = document.querySelector('.progress');
    // progressBar.addEventListener('animationend', myEndFunction);
    // Retrigger Animation on Slide Change
    // function myEndFunction() {
    //     swiper.slideNext();
    //     progressBar.style.animation = 'none';
    //     void progressBar.offsetWidth; // Triggers Reflow
    //     progressBar.style.animation = null;
    // }
    // // Reset Progress Bar On Slide Change
    // swiper.on('slideChange', function () {
    //     progressBar.style.animation = 'none';
    //     void progressBar.offsetWidth; // Triggers Reflow
    //     progressBar.style.animation = null;
    //     progressBar.style.animationPlayState = 'paused'; // Optional
    // });
    // Pause Carousel/Progress Bar On Hover
    // document.querySelectorAll('.swiper, .carousel-progress').forEach((item) => {
    //     item.addEventListener('mouseenter', function () {
    //         progressBar.style.animationPlayState = 'paused';
    //     });
    // });
    // document.querySelectorAll('.swiper, .carousel-progress').forEach((item) => {
    //     item.addEventListener('mouseleave', function () {
    //         progressBar.style.animationPlayState = 'running';
    //     });
    // });
}
