import React from 'react';

import { Helmet } from 'react-helmet';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import HeaderNormal from '../../../components/header/HeaderNormal';
import Footer from '../../../components/footer/Footer';
import FJustifiedGallery from '../../../components/justified-gallery/FJustifiedGallery';

const headerContent = {
    title: 'Christmas Day 2022',
    description: ``,
};

function ChristmasDay() {
    let christmasGalleryItems = [];

    for (let index = 1; index < 219; index++) {
        christmasGalleryItems.push({
            src: `/assets/img/project/project-christmas/${index}.jpg`,
            caption: '',
        });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Kontigo Events - Christmas Day 2022 </title>
            </Helmet>

            {/*Start Header*/}

            <HeaderNormal
                className="text-right"
                description={headerContent.description}
                link="/"
                textLink="Revenir à l'accueil"
                icon={faArrowLeft}
            >
                {headerContent.title}
            </HeaderNormal>

            <FJustifiedGallery images={christmasGalleryItems} className="mb-80" />

            <Footer />
        </React.Fragment>
    );
}

export default ChristmasDay;
