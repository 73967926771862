import React from 'react';
import styled from 'styled-components';

function MetaPost({ date, category, separate, fs }) {
    return (
        <Meta className="post-info" fs={fs}>
            {date && <span className="post-date">{date} </span>}
            {category && (
                <div className="post-cat">
                    {typeof category === 'object' ? (
                        category.map((cat, index) => (
                            <span key={index} data-separate={separate}>
                                {cat}
                            </span>
                        ))
                    ) : (
                        <span>{category}</span>
                    )}
                </div>
            )}
        </Meta>
    );
}

MetaPost.defaultProps = {
    separate: ', ',
};

export const Meta = styled.span`
    font-size: ${(props) => {
        return props.fs ? props.fs : '1.8rem';
    }};
    font-weight: 300;
    letter-spacing: 1.2px;
    word-spacing: 2px;
    text-transform: uppercase;

    span {
        margin-bottom: 8px;
        font-size: 1rem;
        @media (min-width: 991px) {
            font-size: 2rem;
        }
    }

    .post-date,
    .post-cat {
        display: inline-block;
    }

    .post-date {
        padding-right: 20px;
    }

    .post-cat span {
        &:not(:first-child):before {
            content: attr(data-separate);
        }
    }
`;

export default MetaPost;
