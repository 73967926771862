import React, { useEffect, useRef } from 'react';
import style from './style.module.scss';
import { Container } from 'react-bootstrap';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gsap from 'gsap';
import { dsnCN } from '../../hooks/helper';

function HeaderNormal({ className, description, icon, link, textLink, children, ...restProps }) {
    const head = useRef();
    const dot = useRef();

    useEffect(() => {
        gsap.fromTo(
            dot.current,
            { yPercent: 60, autoAlpha: 0.05 },
            {
                yPercent: 100,
                autoAlpha: 0,
                scrollTrigger: {
                    trigger: head.current,
                    start: 'top top',
                    scrub: true,
                },
            }
        );
    });

    return (
        <header className={dsnCN(style.hn, className)} ref={head}>
            <Container {...restProps}>
                <h1 className={`title-heading ${style.ht}`}>{children}</h1>
                {description && (
                    <p className="mt-30 m-w570" style={{ letterSpacing: '1.2px' }}>
                        {description}
                    </p>
                )}
                <a href={link || '#'} className={`view-case ${style.sd} mt-30`}>
                    {textLink || 'Scroll'} <FontAwesomeIcon icon={icon || faArrowDown} />
                </a>
            </Container>
            <div className={style.bcd} ref={dot} />
        </header>
    );
}

export default HeaderNormal;
