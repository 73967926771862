import React from 'react';
import { dsnCN } from '../../hooks/helper';

function Copyright({ className, ...restProps }) {
    return (
        <div className={dsnCN('copyright', className)} {...restProps}>
            <div className="text-center">
                <p>© {new Date().getFullYear()} KONTIGO EVENTS</p>
            </div>
        </div>
    );
}

export default Copyright;
