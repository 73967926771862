import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink as NavLinkBootstrap } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavLink = React.forwardRef(({ children, icon, to, ...restProps }, ref) => {
    return (
        <NavLinkBootstrap
            as={to ? Link : undefined}
            to={to}
            ref={ref}
            className="nav-link-footer"
            {...restProps}
        >
            {icon && (
                <span className="icon">
                    <FontAwesomeIcon icon={icon} />
                </span>
            )}
            {children}
        </NavLinkBootstrap>
    );
});

export default NavLink;
