import React, { useState, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import useEffectLocation from '../hooks/useEffectLocation';
import Confidentiality from '../views/Confidentiality';
// import Work from '../views/Work';
import WorkTow from '../views/WorkTow';
import ChristmasDay from '../views/evenements/KontigoFestif/ChristmasDay';
import NotFound from '../views/NotFound';
import Terms from '../views/Terms';

import './style.scss';

const Home = React.lazy(() => import('../views/home/Home'));

const ProjectDetails = React.lazy(() => import('../views/evenements/ProjectDetails'));

const About = React.lazy(() => import('../views/About'));
const Contact = React.lazy(() => import('../views/Contact'));

const Router = () => {
    const [transPage, setTransPage] = useState('in');
    const scrollbar = useSelector((state) => state.scrollbar);

    const location = useEffectLocation((l) => {
        setTransPage('out');
    });

    const onAnimateEnd = () => {
        if (transPage !== 'out') return;
        scrollbar.current?.scrollTo(0, 0, 0);
        window.scrollTo({ left: 0, top: 0 });
        setTransPage('in');
    };

    return (
        <div
            id="dsn-content"
            className={`dsn-transition-page dsn-animate-${transPage}`}
            onAnimationEnd={onAnimateEnd}
        >
            <Suspense fallback={<div className="background-main h-100-v" />}>
                <Routes location={location}>
                    <Route path="/" element={<Home />} />

                    {/* <Route path="/slider" element={<SliderOne />} />
                    <Route path="/slider-2" element={<SliderTow />} />
                    <Route path="/slider-3" element={<SliderThree />} /> */}

                    {/* <Route exact path="/portfolio" element={<Work />} /> */}
                    <Route exact path="/portfolio" element={<WorkTow />} />
                    <Route exact path="/evenements/:slug" element={<ProjectDetails />} />

                    <Route
                        exact
                        path="/evenements/kontigo-festif/christmas-day"
                        element={<ChristmasDay />}
                    />

                    <Route
                        exact
                        path="/politique-de-confidentialite"
                        element={<Confidentiality />}
                    />
                    <Route exact path="/mentions-legales" element={<Terms />} />
                    {/* <Route exact path="/about" element={<About />} /> */}
                    <Route exact path="/contact" element={<Contact />} />
                    {/* <Route exact path="/blog-details" element={<BlogDetails />} /> */}
                    <Route exact path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </div>
    );
};

export default React.memo(Router);
