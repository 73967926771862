import React from 'react';
import { getPortfolioItem } from '../../api/portfolio/PortfolioData';

import { Container } from 'react-bootstrap';
import { faAngleRight, faStar } from '@fortawesome/free-solid-svg-icons';

//--> Component
import HeaderHalf from '../../components/header/HeaderHalf';
import TitleCover from '../../components/heading/title-cover/TitleCover';
import FadeUpTrigger from '../../animation/FadeUpTrigger';
import TextTrigger from '../../animation/TextTrigger';

import ButtonDefault from '../../components/button/button-default/ButtonDefault';
import FJustifiedGallery from '../../components/justified-gallery/FJustifiedGallery';
import NextProject from '../../components/next/NextProject';
import { Helmet } from 'react-helmet';
import Footer from '../../components/footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const KontigoFestif = ({ data }) => {
    const nextData = getPortfolioItem(1);

    return (
        <React.Fragment>
            <Helmet>
                <title>Kontigo Events - {data.title} </title>
                <meta name="description" content={data.description} />
            </Helmet>
            {/*Start Header Half*/}
            <HeaderHalf
                heroContent={data}
                parallax={{ yPercent: 30, scale: 1.1 }}
                textButton="Revenir à l'accueil"
                href="/"
                overlay={data.overlay}
            >
                <strong className="color-heading"></strong> <span></span>
            </HeaderHalf>
            {/*End Header Half*/}

            {/*Start Info Project*/}
            <Container className="section-margin section-margin-alt-mobile">
                <TitleCover className="mt-30">Événements</TitleCover>
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Festifs
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger stagger={0.5}>
                    <p className="mt-20">
                        Les événements festifs rythment un calendrier souvent chargé. <br />
                        Kontigo saura répondre à vos besoins pour vous permettre de disposer
                        d’événements clé en main. <br />
                        De la journée de rentrée des enseignants alternant les temps professionnels
                        et conviviaux, à la soirée à destination d’une promotion d’élèves, Kontigo
                        dispose de l’ensemble des partenaires et de la logistique, essentiels à la
                        réussite de vos événements.
                    </p>
                    <ButtonDefault
                        className="mt-30 mb-30"
                        icon={faAngleRight}
                        text="Nous Contacter"
                        to="/contact"
                    />
                </FadeUpTrigger>

                <div className="container separator mb-30"></div>
            </Container>
            {/*End Info Project*/}

            <Container className="section-margin">
                <TitleCover from={{ y: -220 }} to={{ y: -80 }} className="text-center">
                    CHRISTMAS
                </TitleCover>
                <FadeUpTrigger stagger={0.8}>
                    <p className="mt-30 text-center">
                        <FontAwesomeIcon icon={faStar} /> Retrouvez l'ensemble des photos de la
                        Christmas Day 2022 <FontAwesomeIcon icon={faStar} />
                    </p>
                    <p className="text-center">
                        <ButtonDefault
                            className="mt-30 btn-alt"
                            icon={faStar}
                            text="Cliquez ici"
                            to="/evenements/kontigo-festif/christmas-day"
                        />
                    </p>
                </FadeUpTrigger>
            </Container>

            <FJustifiedGallery
                images={[
                    { src: '/assets/img/project/project1/1.jpeg', caption: '' },
                    { src: '/assets/img/project/project1/2.jpeg', caption: '' },
                    { src: '/assets/img/project/project1/3.jpeg', caption: '' },
                    { src: '/assets/img/project/project1/4.jpeg', caption: '' },
                    { src: '/assets/img/project/project1/5.jpeg', caption: '' },
                    { src: '/assets/img/project/project1/6.jpeg', caption: '' },
                    { src: '/assets/img/project/project1/7.jpeg', caption: '' },
                    { src: '/assets/img/project/project1/8.jpeg', caption: '' },
                    { src: '/assets/img/project/project1/9.jpeg', caption: '' },
                ]}
                className="section-margin"
            />

            <div className="container separator"></div>
            <NextProject heroContent={nextData} overlay={4} className="half-content--small " />
            <Footer />
        </React.Fragment>
    );
};

export default KontigoFestif;
