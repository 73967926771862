import React from 'react';

import HeaderNormal from '../components/header/HeaderNormal';
import Footer from '../components/footer/Footer';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpaghettiMonsterFlying } from '@fortawesome/free-solid-svg-icons';

const headerContent = {
    title: 'NOT FOUND',
    description: `Oups! La page que vous cherchez n’existe pas...`,
};

function NotFound() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Kontigo Events - Not Found </title>
            </Helmet>

            {/*Start Header*/}

            <HeaderNormal
                className="background-section section-margin"
                description={headerContent.description}
                link="/"
                textLink="Revenir à l'accueil"
                icon={faArrowLeft}
            >
                <FontAwesomeIcon icon={faSpaghettiMonsterFlying} />
                {'  '}
                {headerContent.title}
            </HeaderNormal>

            <Footer />
        </React.Fragment>
    );
}

export default NotFound;
