import KontigoSportif from '../../views/evenements/KontigoSportif';
import KontigoFestif from '../../views/evenements/KontigoFestif';
import KontigoCohesif from '../../views/evenements/KontigoCohesif';

const data = [
    {
        id: 1,
        title: 'KONTIGO SPORTIF',
        slug: 'kontigo-sportif',
        category: [],
        description:
            'Faîtes le choix du sport pour créer ou développer votre événement afin de mobiliser et fédérer les participants autour d’un projet commun.',
        src_alt: '//d2kjel30t2dcp5.cloudfront.net/kontigo_sportif.mp4',
        src: '//d2kjel30t2dcp5.cloudfront.net/kontigo_sportif_lg.mp4',
        srcSlider: '/assets/img/project/ski.jpg',
        overlay: 3,
        fs: '1.2rem',
        video: {
            loop: true,
        },
        component: (props) => <KontigoSportif {...props} />,
    },
    {
        id: 2,
        title: 'KONTIGO COHESIF',
        slug: 'kontigo-cohesif',
        src_alt: '//d2kjel30t2dcp5.cloudfront.net/kontigo_cohesif.mp4',
        src: '//d2kjel30t2dcp5.cloudfront.net/kontigo_cohesif_lg.mp4',
        srcSlider: '/assets/img/project/sport.jpg',
        category: [''],
        description:
            'Mettez la dimension « cohésive » au centre de vos préoccupations et nous vous créerons des événements sur mesures pour renforcer l’identité de vos équipes et de vos promotions.',
        overlay: 0,
        fs: '1.rem',
        video: {
            loop: true,
        },
        component: (props) => <KontigoCohesif {...props} />,
    },
    {
        id: 3,
        title: 'KONTIGO FESTIF',
        slug: 'kontigo-festif',
        src_alt: '//d2kjel30t2dcp5.cloudfront.net/kontigo_festif.mp4',
        src: '//d2kjel30t2dcp5.cloudfront.net/kontigo_festif_lg.mp4',
        srcSlider: '/assets/img/project/wei2.jpg',
        category: [''],
        description:
            'Professionnalisez vos événements festifs en vous faisant accompagner dans leurs créations et leurs animations.',
        overlay: 0,
        fs: '1.2rem',
        video: {
            loop: true,
        },
        component: (props) => <KontigoFestif {...props} />,
    },
];

export const getPortfolioData = () => data;

export const getPortfolioItem = (value, whereName = 'id') => {
    return data.find((item) => {
        if (item[whereName] === value) return item;
        return null;
    });
};
export const getPortfolioLink = (item) => {
    if (item) return item.slug && '/evenements/' + item.slug;

    return '';
};
