import React from 'react';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavLink from '../NavLink';
import { dsnCN } from '../../../hooks/helper';

function ButtonDefault({ icon, text, className, ...restProps }, ref) {
    return (
        <NavLink className={dsnCN('btn-default', className)} {...restProps} ref={ref}>
            {icon && (
                <span className="icon">
                    <FontAwesomeIcon icon={icon} />
                </span>
            )}
            <span className="text">{text}</span>
        </NavLink>
    );
}

export default React.forwardRef(ButtonDefault);
