import React, { useLayoutEffect, useRef } from 'react';
import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';

function SmoothScrollbar({ children, tag: Tag = 'div', refTarget, height, option, ...restProps }) {
    const scrollbar = useRef();
    useLayoutEffect(() => {
        if (option.plugins) Scrollbar.use(OverscrollPlugin);

        const scroll = Scrollbar.init(scrollbar.current, option);
        if (refTarget) refTarget(scroll, scrollbar.current);

        return () => {
            scroll.destroy();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Tag {...restProps} ref={scrollbar} style={{ overflow: 'hidden', height: height }}>
            {children}
        </Tag>
    );
}

SmoothScrollbar.defaultProps = {
    height: '100vh',
    option: {},
};

export default SmoothScrollbar;
