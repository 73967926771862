import React from 'react';
import HeaderDefault from '../components/header/HeaderDefault';

import DsnGrid from '../components/DsnGrid';
import { Container } from 'react-bootstrap';

import Footer from '../components/footer/Footer';

function Terms({ props }) {
    const heroContent = {
        title: 'Mentions Légales',
        date: '',
        category: [],
        src: '',
    };

    return (
        <>
            <HeaderDefault
                heroContent={heroContent}
                parallax={{ yPercent: 30 }}
                overlay={4}
                height="80vh"
            />

            <Container className="single-post post-content mb-section">
                <DsnGrid>
                    <p>
                        Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la
                        confiance en l'économie numérique, il est précisé aux utilisateurs du site
                        KONTIGO-EVENTS l'identité des différents intervenants dans le cadre de sa
                        réalisation et de son suivi.
                        <br />
                        <br />
                        Edition du site
                        <br />
                        <br />
                        Le présent site, accessible à l’URL kontigo.fr (le « Site »), est édité par
                        :
                        <br />
                        <br />
                        Marin Chirade, résidant 50 RUE ERNEST RENAN 92190 MEUDON, de nationalité
                        Française (France), né(e) le 12/02/1988, inscrite au R.C.S. de NANTERRE sous
                        le numéro Nanterre B 900 463 704,
                        <br />
                        <br />
                        Le numéro individuel TVA de l’éditeur est : FR03900463704.
                        <br />
                        <br />
                        <br />
                        Hébergement
                        <br />
                        <br />
                        Le Site est hébergé par la société Vercel, situé 340 S Lemon Ave #4133
                        Walnut, CA 91789, (contact téléphonique ou email : +15062340000).
                        <br />
                        <br />
                        Directeur de publication
                        <br />
                        <br />
                        Le Directeur de la publication du Site est Marin Chirade.
                        <br />
                        <br />
                        Nous contacter
                        <br />
                        <br />
                        Par téléphone : +33658862727
                        <br />
                        Par email : contact@kontigo.fr
                        <br />
                        Par courrier : 50 RUE ERNEST RENAN 92190 MEUDON
                        <br />
                    </p>
                </DsnGrid>
                {/* <CommentForm className="mt-section" /> */}
            </Container>

            <Footer />
        </>
    );
}

export default Terms;
