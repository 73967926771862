import React from 'react';
import Navbar, { Nav } from '../../nav/Navbar';
import Brand from '../../brand/Brand';
import MenuContent from './MenuContent';

const menuContent = [
    {
        name: 'Accueil',
        to: '/',
    },
    { name: "Nos types d'événements", to: '/portfolio' },
    {
        name: 'Politique de confidentialité',
        to: '/politique-de-confidentialite',
    },
    { name: 'Mentions Légales', to: '/mentions-legales' },
    { name: 'Contact', to: '/contact' },
];

const HamburgerMenu = ({ hamburger }) => {
    let $key = 0;
    const getSubMenu = (items) => {
        $key++;
        if (items.dropdownMenu) {
            return (
                <Nav.Dropdown name={items.name} key={$key}>
                    {items.dropdownMenu.map((item) => getSubMenu(item))}
                </Nav.Dropdown>
            );
        }
        return (
            <Nav.Link to={items.to} key={$key}>
                {items.name}
            </Nav.Link>
        );
    };

    return (
        <Navbar hamburger={hamburger}>
            <Navbar.TopBar></Navbar.TopBar>
            <Navbar.Brand to={'/'}>
                <Brand width="120" height="auto" />
            </Navbar.Brand>

            <Navbar.Collapse cover="KONTIGO">
                <Nav>{menuContent.map((item) => getSubMenu(item))}</Nav>
                <MenuContent className="section-margin" />
            </Navbar.Collapse>
        </Navbar>
    );
};

HamburgerMenu.defaultProps = {
    hamburger: false,
};

export default HamburgerMenu;
