import React from 'react';
import { dsnCN } from '../../hooks/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function ContentFooter({ className, ...restProps }) {
    return (
        <div className={dsnCN('col-menu', className)} {...restProps}>
            {/* <p>
                <strong>
                    {' '}
                    <FontAwesomeIcon icon={faPhone} />{' '}
                </strong>{' '}
                <span>:</span>{' '}
                <a className="link-hover" href="tel:0658862727‬" data-hover-text="0658862727‬">
                    06 58 86 27 27‬
                </a>
            </p> */}
            <p className="mt-2">
                <strong>@</strong> <span>:</span>{' '}
                <a href="mailto:contact@kontigo.fr?subject=Kontigo.fr - Demande de renseignements">
                    {' '}
                    contact@kontigo.fr
                </a>
            </p>
        </div>
    );
}

export default ContentFooter;
