import React from 'react';

import MainScrollBar from './MainScrollBar';
import HamburgerMenu from '../components/header/menu/HambugerMenu';
// import LoadingPage from '../hooks/LoadingPage';
import CustomCursor from './CustomCursor';

function Layout({ className, children, tag: Tag = 'div', activeScrollbar = true, ...restProps }) {
    return (
        <Tag id="main_layout" className={className}>
            <HamburgerMenu hamburger />
            {/* <LoadingPage /> */}
            {activeScrollbar ? <MainScrollBar {...restProps}>{children}</MainScrollBar> : children}
            <CustomCursor
                duration={0.5}
                durationChangeSize={0.3}
                size={20}
                scale={75}
                ease="power2.out"
            />
        </Tag>
    );
}

export default Layout;
