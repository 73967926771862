import React from 'react';
import { getPortfolioItem } from '../../api/portfolio/PortfolioData';

import { Container } from 'react-bootstrap';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

//--> Component
import HeaderHalf from '../../components/header/HeaderHalf';
import TitleCover from '../../components/heading/title-cover/TitleCover';
import FadeUpTrigger from '../../animation/FadeUpTrigger';
import TextTrigger from '../../animation/TextTrigger';
import ButtonDefault from '../../components/button/button-default/ButtonDefault';
import NextProject from '../../components/next/NextProject';
import { Helmet } from 'react-helmet';
import Footer from '../../components/footer/Footer';
import FJustifiedGallery from '../../components/justified-gallery/FJustifiedGallery';

const KontigoSportif = ({ data }) => {
    const nextData = getPortfolioItem(2);

    return (
        <React.Fragment>
            <Helmet>
                <title>Kontigo Events - {data.title} </title>
                <meta name="description" content={data.description} />
            </Helmet>
            {/*Start Header Half*/}
            <HeaderHalf
                heroContent={data}
                parallax={{ yPercent: 30, scale: 1.1 }}
                textButton="Revenir à l'accueil"
                href="/"
                overlay={data.overlay}
            >
                <strong className="color-heading"></strong> <span></span>
            </HeaderHalf>
            {/*End Header Half*/}

            {/*Start Info Project*/}
            <Container className="section-margin">
                <TitleCover>Événements</TitleCover>
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Sportifs
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger stagger={0.5}>
                    <p className="mt-20">
                        Choisir Kontigo, c’est avant tout faire appel à une agence événementielle
                        basée sur la dynamique sportive. <br />
                        Le sport comme vecteur événementiel vous permettra de fédérer des centaines
                        de participants autour d’objectifs communs (caritatifs, pédagogiques,
                        sanitaires,…) <br />
                        Kontigo sera votre précieux allié pour vous permettre de créer ou de
                        renforcer votre événement sportif. <br />
                        Un appui logistique et opérationnel indispensable pour susciter l’engouement
                        des participants et leur fidélisation.
                    </p>
                    <ButtonDefault
                        className="mt-30"
                        icon={faAngleRight}
                        text="Nous Contacter"
                        to="/contact"
                    />
                </FadeUpTrigger>
            </Container>
            {/*End Info Project*/}

            <FJustifiedGallery
                images={[
                    { src: '/assets/img/project/project3/1.jpeg', caption: '' },
                    { src: '/assets/img/project/project3/2.jpeg', caption: '' },
                    { src: '/assets/img/project/project3/3.jpeg', caption: '' },
                    { src: '/assets/img/project/project3/4.jpeg', caption: '' },
                    { src: '/assets/img/project/project3/5.jpeg', caption: '' },
                    { src: '/assets/img/project/project3/6.jpeg', caption: '' },
                ]}
                className="section-margin"
            />

            <div className=" container separator"></div>
            <NextProject heroContent={nextData} overlay={4} className="half-content--small " />
            <Footer />
        </React.Fragment>
    );
};

export default KontigoSportif;
