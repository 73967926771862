import React from 'react';
import { dsnCN } from '../../../hooks/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function MenuContent({ className }) {
    const socialData = [
        { link: 'https://www.instagram.com/kontigo_events/', icon: faInstagram, name: 'Instagram' },
        { link: 'https://www.youtube.com/@kontigo-events9055', icon: faYoutube, name: 'Youtube' },
    ];
    return (
        <div
            className={dsnCN(
                'container-content  d-flex flex-column justify-content-center',
                className
            )}
        >
            <div className="nav__info">
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Kontigo Events</h5>
                </div>
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Coordonnées</h5>
                    {/* <p className="links over-hidden mb-1">
                        <strong>
                            {' '}
                            <FontAwesomeIcon icon={faPhone} />{' '}
                        </strong>{' '}
                        <span>:</span>{' '}
                        <a
                            className="link-hover"
                            href="tel:0658862727‬"
                            data-hover-text="0658862727‬"
                        >
                            06 58 86 27 27‬
                        </a>
                    </p> */}
                    <p className="links over-hidden">
                        <strong>@</strong> <span>:</span>{' '}
                        <a
                            className="link-hover"
                            href="mailto:contact@kontigo.fr"
                            data-hover-text="contact@kontigo.fr"
                        >
                            contact@kontigo.fr
                        </a>
                    </p>
                </div>
            </div>
            <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Follow us</h5>
                    <ul>
                        {socialData.map((item, index) => (
                            <li key={index}>
                                <a
                                    href={item.link}
                                    target="_blank"
                                    rel="nofollow noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon={item.icon} /> - {item.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuContent;
