import React from 'react';
import { getPortfolioItem } from '../../api/portfolio/PortfolioData';

import { Container } from 'react-bootstrap';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

//--> Component
import HeaderHalf from '../../components/header/HeaderHalf';
import TitleCover from '../../components/heading/title-cover/TitleCover';
import FadeUpTrigger from '../../animation/FadeUpTrigger';
import TextTrigger from '../../animation/TextTrigger';

import ButtonDefault from '../../components/button/button-default/ButtonDefault';
import FJustifiedGallery from '../../components/justified-gallery/FJustifiedGallery';
import NextProject from '../../components/next/NextProject';
import { Helmet } from 'react-helmet';
import Footer from '../../components/footer/Footer';

const KontigoCohesif = ({ data }) => {
    const nextData = getPortfolioItem(3);

    return (
        <React.Fragment>
            <Helmet>
                <title>Kontigo Events - {data.title} </title>
                <meta name="description" content={data.description} />
            </Helmet>
            {/*Start Header Half*/}
            <HeaderHalf
                heroContent={data}
                parallax={{ yPercent: 30, scale: 1.1 }}
                textButton="Revenir à l'accueil"
                href="/"
                overlay={data.overlay}
            >
                <strong className="color-heading"></strong> <span></span>
            </HeaderHalf>
            {/*End Header Half*/}

            {/*Start Info Project*/}
            <Container className="section-margin">
                <TitleCover>Événements</TitleCover>
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Cohésifs
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger stagger={0.5}>
                    <p className="mt-20">
                        C’est le produit phare de notre agence : la journée de cohésion ! <br />
                        De quelques dizaines, à plusieurs centaines de participants, faîtes appel à
                        Kontigo pour booster vos équipes ou vos promotions. <br />À vous de placer
                        les curseurs sur vos objectifs et notre agence vous proposera des produits
                        clé en main répondant parfaitement à vos attentes tout en respectant vos
                        impératifs structurels et organisationnels.
                    </p>
                    <ButtonDefault
                        className="mt-30"
                        icon={faAngleRight}
                        text="Nous Contacter"
                        to="/contact"
                    />
                </FadeUpTrigger>
            </Container>
            {/*End Info Project*/}

            <FJustifiedGallery
                images={[
                    { src: '/assets/img/project/project2/1.jpg', caption: '' },
                    { src: '/assets/img/project/project2/2.jpg', caption: '' },
                    { src: '/assets/img/project/project2/3.jpg', caption: '' },
                    { src: '/assets/img/project/project2/4.jpg', caption: '' },
                    { src: '/assets/img/project/project2/5.jpg', caption: '' },
                    { src: '/assets/img/project/project2/6.jpg', caption: '' },
                    { src: '/assets/img/project/project2/7.jpg', caption: '' },
                    { src: '/assets/img/project/project2/8.jpg', caption: '' },
                    { src: '/assets/img/project/project2/9.jpg', caption: '' },
                    { src: '/assets/img/project/project2/10.jpg', caption: '' },
                    { src: '/assets/img/project/project2/11.jpg', caption: '' },
                    { src: '/assets/img/project/project2/12.jpg', caption: '' },
                    { src: '/assets/img/project/project2/13.jpg', caption: '' },
                    { src: '/assets/img/project/project2/14.jpg', caption: '' },
                    { src: '/assets/img/project/project2/15.jpg', caption: '' },
                    { src: '/assets/img/project/project2/16.jpg', caption: '' },
                    { src: '/assets/img/project/project2/17.jpg', caption: '' },
                    { src: '/assets/img/project/project2/18.jpg', caption: '' },
                ]}
                className="section-margin"
            />

            <div className="container separator"></div>
            <NextProject heroContent={nextData} overlay={4} className="half-content--small " />
            <Footer />
        </React.Fragment>
    );
};

export default KontigoCohesif;
